<template>
  <div class="experimental-pages-container">
    <div class="disclaimer">
      <div class="title">המעבדה של RiseUp</div>
      <div> 💡 כאן אנחנו מנסים רעיונות חדשים. אם אהבת כאן משהו או אם מצאת תקלה - נשמח לשמוע.
        חשוב לזכור ששום דבר כאן לא ״מוכן״. בעזרת המשוב שלך חלק מהרעיונות כאן עוד יהיו חלק רשמי מהמוצר!</div>
    </div>
    <div class="menu-items">
      <navigation-button @navigate-clicked="navigate('CashflowFlow')" button-text="הזרימה של התזרים" image-path="nav/subscription-settings.svg"/>
      <navigation-button @navigate-clicked="navigate('DataCenter')" button-text="מרכז התובנות" :icon-props="dataCenterIconProps"/>
      <navigation-button @navigate-clicked="navigate('AnonymizeCashflow')" button-text="להפוך את התזרים לאנונימי"
                         image-path="nav/subscription-settings.svg"/>
      <navigation-button @navigate-clicked="navigate('EnableMobile')" button-text="אפליקציית מובייל" image-path="nav/subscription-settings.svg"/>
      <navigation-button @navigate-clicked="navigate('GetExcel')" button-text="להוריד את התזרים לאקסל" image-path="nav/subscription-settings.svg"/>
      <navigation-button @navigate-clicked="navigate('Voucher')" button-text="לקנות שובר" :icon-props="voucher"/>
    </div>
  </div>
</template>

<script>
import Segment from '@/Segment';
import NavigationButton from '@/base-components/app-header/NavigationButton';
import router from '@/router';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'ExperimentalPages',
  components: {
    NavigationButton,
  },
  data() {
    return {
      dataCenterIconProps: {
        iconName: 'analytics',
        fill: true,
        color: BaseUI.Colors.riseupBlue,
      },
      voucher: {
        iconName: 'shopping_bag',
        fill: true,
        color: BaseUI.Colors.riseupBlue,
      },
    };
  },
  async created() {
    Segment.trackUserGot('ExperimentalPagesEntered');
  },
  methods: {
    navigate(name) {
      return router.push({ name, query: this.$route.query });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.experimental-pages-container {
  font-family: SimplerPro;
  .disclaimer {
    padding: 30px 24px;
    color: $riseup_white;
    text-align: right;
    line-height: 24px;
    font-size: 18px;
    background-color: $riseup-blue;
    .title {
      font-size: 32px;
      margin-bottom: 16px;
    }
    .bold {
      font-family: bold;
    }
  }
  .menu-items {
    position: relative;
    padding: 0 25px;
    border-top: solid 1px $riseup-gray_0;
    border-bottom: solid 1px $riseup-gray_0;
  }
}
</style>
